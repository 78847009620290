import React from 'react'

import * as styles from './styles.module.css'

interface Props {
	jobs: JobOfferType[]
}

function CareersList({ jobs }: Props) {
	const paycomUrl = 'https://www.paycomonline.net/v4/ats/web.php/jobs?clientkey=B15DFBA39FBE4331D26EB3FDACEEBA04&fromClientSide=true'

	return (
		<section className={styles.section}>
			<div className='container'>
				<iframe src={paycomUrl} className={styles.frame}></iframe>
			</div>
		</section>
	)
}

export default CareersList
