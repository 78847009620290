import React from 'react'
import { graphql } from 'gatsby'

import Hero from '@/components/organisms/Hero'
import ImageContent from '@/components/organisms/ImageContent'
import Layout from '@/components/organisms/Layout'
import Seo from '@/components/organisms/Seo'
import CareersList from '@/components/organisms/CareersList'

interface Props {
	data?: {
		content: {
			seo?: SeoMetagagsType
			section_hero?: HeroType
			section_image_content?: ImageContentType[]
		}
		jobs: {
			edges: { node: JobOfferType }[]
		}
	}
}
// <div style={{height: '1400px', maxWidth: 'calc(100% - 125px)', paddingLeft: '20px', overflow: 'auto', border: '0'}}>
export default function Careers({ data }: Props) {
	const { content, jobs } = data || {}
	const flattenedJobs = jobs?.edges.map(({ node }) => node) || []
	return (
		<Layout>
			<div>
				<Seo {...content?.seo} />
				<Hero {...content?.section_hero} />
				{content?.section_image_content?.map((item) => (
					<ImageContent key={item.id} {...item} />
				))}
				<CareersList jobs={flattenedJobs} />
			</div>
		</Layout>
	)
}

export const CareersPageQuery = graphql`
	query {
		content: strapiPageCareer {
			seo {
				description
				title
			}

			section_hero {
				background {
					localFile {
						childImageSharp {
							gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP, JPG])
						}
					}
				}
				bg_dark_level
				content {
					data {
						content
					}
				}
			}

			section_image_content {
				heading
				content
				left_side_image
				id
				image {
					alternativeText
					localFile {
						childImageSharp {
							gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP, JPG])
						}
					}
				}
			}

		}
		jobs: allStrapiJob {
			edges {
				node {
					description
					id
					title
					state
					emailLink
				}
			}
		}
	}
`
